.header {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #243954;
  padding: 0 10%;
}

.logo {
  font-size: 2rem;
  color: white;
  font-weight: bold;
}

.my-header {
  position: sticky;
  top: 0;
  z-index: 7;

  &__logo {
    position: relative;
    display: flex;
    align-items: center;

    svg {
      height: 42px;

      @media (768px) {
        height: auto;
      }
    }

    .logo__link {
      display: flex;

      svg {
        @media (768px) {
          margin-right: 24px;
          margin-top: 24px;
          padding-right: 24px;
          padding-top: 24px;
        }
      }
    }
  }
}

.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.header li {
  margin-left: 3rem;
}

.header a {
  text-decoration: none;
  font-size: 1.5rem;
  color: #bee5fb;
}

.header a:hover,
.header a:active,
.header a.active {
  color: white;
}