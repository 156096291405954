.footer {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #243954;
  margin: 0;
}

.content {
  text-align: center;
  padding: 0 10%;
  color: white;
}