//.main {
//  margin: 3rem auto;
//  width: 90%;
//  max-width: 60rem;
//}

.my-app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: whitesmoke;

  .main {
    margin: 0px auto;
    width: 100%;
    max-width: 60rem;
    background-color: #ffffff;
    padding: 3rem;
  }
  .wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    margin: 0;
    padding: 0;
    max-width: 100%;
  }

  .app__grid {
    display: flex;
    flex-direction: column;
  }

}